














import { Vue, Component } from 'vue-property-decorator';
import { axios } from '@/configs/axios';
import { ToastProgrammatic } from 'buefy';
import ResetPasswordTemplate from '@/components/login/ResetPasswordTemplate.vue';
import { ResetPasswordResponse } from '@/models/users';
import ForgetPasswordLayout from '@/components/login/ForgetPasswordLayout.vue';
@Component({
    components: { ResetPasswordTemplate, ForgetPasswordLayout },
})
export default class Login extends Vue {

    created() {
        // Check the current URL for token
        if (!this.$route.query.token) {
            this.$router.push({
                path: `/login`
            });
        }
    }

    async mounted() {
        // Validate token
        if (this.$route.query.token) {
            const response = await axios.post(`auth/validate`, {
                token: this.$route.query.token,
            });
            if (response.data.code === 500) {
                ToastProgrammatic.open({
                    type: `is-danger`,
                    duration: 3000,
                    message: `Token has expired or is invalid, Please request another token again`
                });
                this.$router.push({
                    path: `login`
                });
            }
        }
    }

    resetAccountPassword(payload: string) {
        axios.post<ResetPasswordResponse>(`auth/resetPassword`, {
            password: payload,
            token: this.$route.query.token,
        }).then((response) => {
            if (response.data.code === 200) {
                this.$router.push({
                    path: `/reset_password_success`
                });
            }

            if (response.data.code === 500 && response.data.error!.includes(`Invalid token`)) {
                ToastProgrammatic.open({
                    type: `is-danger`,
                    duration: 3000,
                    message: `Token is invalid, Please request another token again`
                });
                
            }

            if (response.data.code === 500 && response.data.error!.includes(`Token expire`)) {
                ToastProgrammatic.open({
                    type: `is-danger`,
                    duration: 3000,
                    message: `Token has expired, Please request another token again`
                });
                
            }

            if (response.data.code === 500 && response.data.error!.includes(`Cannot update the password`)) {
                ToastProgrammatic.open({
                    type: `is-danger`,
                    duration: 3000,
                    message: `Password cannot be updated, Do request the administrator for help`
                });
                
            }
        });
    }
}
