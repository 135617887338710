






































import { PASSWORD_REGEX } from '@/utils/regex';
import { Vue, Component, Emit } from 'vue-property-decorator';
@Component({})
export default class ForgetPasswordTemplate extends Vue {
  currentPassword = '';
  confirmNewPassword = '';

  showPasswordError = false;
  showConfirmPasswordError = false;

  get passwordErrorMessage() {
    return `Invalid Password. Password has to contain 1 capital letter and 1 special character`;
  }

  get confirmPasswordErrorMessage() {
    return `Invalid Confirm Password. This password has to be the same as Password`;
  }

  get isButtonDisabled() {
    return (
      this.showPasswordError ||
      this.showConfirmPasswordError ||
      this.currentPassword.length === 0 ||
      this.confirmNewPassword.length === 0
    );
  }

  validatePassword() {
    this.showPasswordError = !PASSWORD_REGEX.test(this.currentPassword);
    this.validateConfirmPassword();
  }

  validateConfirmPassword() {
    this.showConfirmPasswordError =
      !PASSWORD_REGEX.test(this.confirmNewPassword) ||
      this.confirmNewPassword !== this.currentPassword;
  }

  @Emit(`reset`)
  resetPassword() {
    return this.currentPassword;
  }
}
